
/**
 * @name: 商品品牌
 * @author: itmobai
 * @date: 2023-06-12 15:35
 * @description：商品品牌
 * @update: 2023-06-12 15:35
 */
import {Vue, Component} from 'vue-property-decorator';
import { checkPositiveInteger } from "@/constants/validators"
import {IProductBrand, IProductBrandParam} from "@/apis/product/brand/types";
import {ICrudOption} from "@/types/m-ui-crud";
import {ISort} from "@/apis/product/classification/types";
import {
  productBrandCreateApi,
  productBrandDetailApi,
  productBrandGetSecondSortListApi, productBrandModifyApi,
  productBrandQueryApi
} from "@/apis/product/brand";
import config from "@/config";

@Component({})
export default class brand extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格总数
  tableTotal: number = 0;
  // 表格数据
  tableData: IProductBrand[] = []
  // 表单
  modelForm: Partial<IProductBrand> = {}
  // 查询参数
  queryParam: IProductBrandParam = {
    page: 1,
    limit: 10
  }
  // crudOption
  option: ICrudOption = {
    labelWidth: '120px',
    addTitle: '新增商品品牌',
    editTitle: '编辑商品品牌',
    dialogWidth: '600px',
    menuWidth: 120,
    column: [
      {
        "label": "ID",
        "prop": "id",
        "align": "left",
        "width": "180",
        "addHide": true,
        "editHide": true
      },

      {
        "label": "品牌名称",
        "prop": "brandName",
        "search": true,
        width: 200,
        overHidden: true,
        align: "center",
        maxlength: 10,
        "rules": [
          {required: true, message: '请输入品牌名称', trigger: 'blur'}
        ]
      },
      {
        "label": "品牌logo",
        "prop": "brandIcon",
        "align": "center",
        "type": "image",
        "imgPrefix": config.downloadUrl,
        "addSlot": true,
        "editSlot": true,
        "rules": [
          {required: true, message: '请上传品牌logo', trigger: 'blur'}
        ]
      },
      {
        "label": "供应(二级)分类",
        "prop": "sortName",
        "align": "center",
        "overHidden": true,
        editHide: true,
        addHide: true
      },
      {
        "label": "供应(二级)分类",
        "prop": "sortIds",
        "align": "center",
        hide: true,
        type: "select",
        // @ts-ignore
        multiple: true,
        filterable: true,
        dicData: []
      },
      {
        "label": "状态",
        "prop": "status",
        "align": "center",
        width: 100,
        type: "select",
        search: true,
        addHide: true,
        editHide: true,
        dicData: [
          {
            label: "启用",
            value: 1
          },
          {
            label: "禁用",
            value: 2
          },
        ],
        slot: true
      }
    ]
  }

  getShopSortSelect () {
    productBrandGetSecondSortListApi().then(e => {
      // @ts-ignore
      this.$refs.crudRef.updateFormColumn('sortIds', {
        dicData: e.map(item => {
          return {
            label: item.sortName,
            value: item.id
          }
        })
      })
    })
  }

  openAdd () {
    // @ts-ignore
    this.$refs.crudRef.rowAdd()
    this.getShopSortSelect()
  }

  openEdit (row: any, index: number) {
    productBrandDetailApi(row.id).then(e => {
      // @ts-ignore
      this.$refs.crudRef.rowEdit(e, index)
      this.getShopSortSelect()
    })
  }

  rowSave (form: IProductBrand, done: Function, loading: Function) {
    productBrandCreateApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: IProductBrand, done: Function, loading: Function) {
    productBrandModifyApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  switchStatus (id: string, status: number) {
    productBrandModifyApi({id, status} as IProductBrand).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  getList () {
    this.tableLoading = true
    productBrandQueryApi(this.queryParam).then(e => {
      this.tableTotal = e.total;
      this.tableData = e.list;
      this.tableLoading = false
    })
  }

  //上传前检验
  beforeUpload(file: File): boolean {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 0.5;

    if (!isJPG) {
      this.$message.error('上传图片只能是JPG、JPEG、PNG格式!');
    }
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 500KB!');
    }
    return isJPG && isLt2M;
  }

  created () {
    this.getList()
  }
}
